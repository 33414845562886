export const steps = [
  {
    title: 'License Info',
    content: 'First-content',
  },
  {
    title: 'Project Info',
    content: 'Second-content',
  },
  {
    title: 'Project access Info',
    content: 'Third-content',
  },
  {
    title: 'Database Info',
    content: 'Fourth-content',
  },
  {
    title: 'User Info',
    content: 'Fifth-content',
  },
  // {
  //   title: 'System Info',
  //   content: 'Sixth-content',
  // },
  // {
  //   title: 'Finish',
  //   content: 'Finish',
  // },
];
