export const PROJECT_NAME = 'Goshops';
export const BASE_URL = 'https://back.archisource.fr';
export const WEBSITE_URL = 'https://admin.archisource.fr';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyAY2U0HVP1wcdQ61RAlm5Nc37rHw5RcROA';
export const export_url = BASE_URL + '/storage/';
export const VAPID_KEY =
  'BGpdMJfUm3JOga7NI_4k55vUoSnfj1UNKexk90i4mmRh1o8FzjKHvJfQih9HaoZP0wey-vjmk75llZeNvkk1EvM';


export const RECAPTCHASITEKEY = '6LcjDBIqAAAAAEVyolkfeXbNI2Aayh_0wxgntFzc';
export const API_KEY = 'goshops-b1eec.firebaseapp.com';
export const AUTH_DOMAIN = 'goshops-7c405.firebaseapp.com';
export const PROJECT_ID = 'goshops-b1eec';
export const STORAGE_BUCKET = 'goshops-b1eec.appspot.com';
export const MESSAGING_SENDER_ID = '64504803265';
export const APP_ID = '1:64504803265:web:4a7f9540a15a802ade4940';
export const MEASUREMENT_ID = 'G-R5J97BW2HM';
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];

export const DEMO_SELLER = 210; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id
